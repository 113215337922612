<!--
 * @Description: 
 * @Author: tanyuyun
 * @Date: 2024-10-21 10:09:51
 * @LastEditors: tanyuyun
 * @LastEditTime: 2024-12-19 17:11:09
-->
<script setup lang="ts">
import { useDesign } from '@/hooks/web/useDesign'
import { propTypes } from '@/utils/propTypes'
import { getSlot } from '@/utils/tsxHelper'
import { useSlots } from 'vue'

defineProps({
  header: propTypes.string
})

const slots = useSlots()

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('card')

const hasContentSlot = getSlot(slots, 'content') || getSlot(slots)
</script>

<template>
  <div :class="`${prefixCls}`">
    <div
      :class="[`${prefixCls}_header`, { 'mb-20px': hasContentSlot }]"
      v-if="getSlot(slots, 'header') || header"
    >
      <slot name="header"> {{ header }}</slot>
    </div>
    <div :class="`${prefixCls}_content`" v-if="hasContentSlot">
      <slot name="content"></slot>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="less" scoped>
@prefix-cls: ~'@{adminNamespace}-card';

.@{prefix-cls} {
  width: 100%;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px 1px rgb(193 209 230 / 13%);
  box-sizing: border-box;

  &_header {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;

    &::before {
      display: block;
      width: 4px;
      height: 16px;
      margin-right: 12px;
      background: var(--el-color-primary);
      border-radius: 2px;
      content: '';
    }
  }
}
</style>
